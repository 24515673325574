import React from "react";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Portal,
} from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { twMerge } from "tailwind-merge";
import { EllipsisVerticalIcon } from "@heroicons/react/24/outline";

type CustomMenuItem = {
  title: string;
  onClick: () => void;
};

type Props = {
  containerClassName?: string;
  menuClassName?: string;
  title: string;
  menuItems: CustomMenuItem[];
  withThreeDots?: boolean;
};

const menuItemClassName =
  "block px-4 w-full py-2 text-sm text-left text-gray-700 data-[focus]:bg-gray-100 data-[focus]:text-gray-900";

export default function CustomDropdown({
  containerClassName,
  title,
  menuClassName,
  menuItems,
  withThreeDots = false,
}: Props) {
  return (
    <Menu
      as="div"
      className={twMerge(
        "relative inline-block text-left z-10",
        containerClassName
      )}
    >
      {withThreeDots ? (
        <div>
          <MenuButton className="flex items-center rounded-full bg-gray-100 text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
            <span className="sr-only">Open options</span>
            <EllipsisVerticalIcon aria-hidden="true" className="h-5 w-5" />
          </MenuButton>
        </div>
      ) : (
        <div>
          <MenuButton
            className={twMerge(
              "inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50",
              menuClassName
            )}
          >
            {title}
            <ChevronDownIcon
              aria-hidden="true"
              className="-mr-1 h-5 w-5 text-gray-400"
            />
          </MenuButton>
        </div>
      )}

      <Portal>
        <MenuItems
          anchor="bottom end"
          className="z-[100] mt-2 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 transition focus:outline-none data-[closed]:scale-95 data-[closed]:transform data-[closed]:opacity-0 data-[enter]:duration-100 data-[leave]:duration-75 data-[enter]:ease-out data-[leave]:ease-in"
        >
          <div className="py-1">
            {menuItems.map((item) => (
              <MenuItem key={item.title}>
                {({ active }) => (
                  <button
                    onClick={item.onClick}
                    className={twMerge(
                      menuItemClassName,
                      active ? "bg-gray-100 text-gray-900" : ""
                    )}
                  >
                    {item.title}
                  </button>
                )}
              </MenuItem>
            ))}
          </div>
        </MenuItems>
      </Portal>
    </Menu>
  );
}
